@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "Bai Jamjuree";
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  background-color: #ffffff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.react-tooltip {
  z-index: 9999;
}
