body {
  background-color: #0f172a;
}

::-webkit-scrollbar {
  display: none;
}

.tooltip {
  max-width: 100% !important;
  background: #334155 !important;
  color: white !important;
  border-radius: 0.75rem !important;
  padding: 0.4rem 0.75rem !important;
  opacity: 1 !important;
}

@media only screen and (min-width: 420px) {
  .tooltip {
    max-width: 400px !important;
  }
}
